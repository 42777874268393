import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { SubscriptionPlan } from "~/backend/graphql/globalTypes";
import { ActivatePlanButton } from "~/pages/pricing/ActivatePlanButton";
import { ContactButton } from "../buttons/contact/ContactButton";
import { Banner } from "./Banner";
import { BlueInfoIcon } from "./BlueInfoIcon";

const useStyles = makeStyles((theme) => ({
  root: {},
  content2: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .MuiButtonBase-root": {
        display: "block",
      },
    },
  },
}));

interface IProps {
  className?: string;
}

export const SeasonalPromoBanner = ({ className }: IProps) => {
  const classes = useStyles();

  const userSubQuery = useMe();

  const plan = userSubQuery.data?.me?.subscription?.plan;

  const date = new Date();
  const promoName =
    date.getTime() > Date.parse("2024-12-02T06:00:00Z")
      ? "Cyber Monday"
      : "Black Friday";
  if (
    date.getTime() < Date.parse("2024-11-20T00:00:00Z") ||
    date.getTime() > Date.parse("2024-12-04T08:00:00Z")
  ) {
    return null;
  }

  if (userSubQuery.loading) {
    return <Banner hidden={true} />;
  } else if (plan) {
    // Can user upgrade?
    if ([SubscriptionPlan.MONTHLY, SubscriptionPlan.PRO2020].includes(plan)) {
      return (
        <Banner
          className={clsx(classes.root, className)}
          leftAccessory={<BlueInfoIcon />}
        >
          <div className={classes.content2}>
            <strong>{promoName.toUpperCase()} SPECIAL</strong> 30% off our
            Annual subscription{"  "}
            <ContactButton
              actionName="upgrade-annual-blackfriday"
              variant="link"
              prompt={`I would like to switch to an annual subscription with the ${promoName} 30% off price.`}
            >
              Upgrade Today
            </ContactButton>
          </div>
        </Banner>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Banner
        className={clsx(classes.root, className)}
        leftAccessory={<BlueInfoIcon />}
      >
        <div className={classes.content2}>
          <strong>{promoName.toUpperCase()} SPECIAL</strong> 30% off our Annual
          and Coach subscriptions&nbsp;&nbsp;
          {"  "}
          <ActivatePlanButton
            plan={SubscriptionPlan.ANNUAL}
            fullWidth={false}
            startIcon={null}
          >
            Subscribe today
          </ActivatePlanButton>
        </div>
      </Banner>
    );
  }
};
