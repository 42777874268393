import { createContext, useContext } from "react";
import { SessionInfo } from "~/backend/graphql/SessionInfo";
import { SailingSessionEdit } from "~/model/SailingSessionEdit";

export interface ISessionContext {
  session?: SessionInfo;

  editSession: (edit: SailingSessionEdit) => void;
}

export const SessionContext = createContext<ISessionContext>({
  session: undefined,

  editSession: () => true,
});

export const useSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (!ctx) {
    throw new Error(
      "useSessionContext must be used within a SessionContext.Provider"
    );
  }
  if (!ctx.session) {
    throw new Error(
      "useSessionContext must be used within a SessionContext.Provider with a session"
    );
  }
  return ctx as ISessionContext & { session: SessionInfo };
};

export const useSessionContextNullable = () => {
  const ctx = useContext(SessionContext);
  if (!ctx) {
    throw new Error(
      "useSessionContext must be used within a SessionContext.Provider"
    );
  }
  return ctx;
};
